import Vue from 'vue';
import App from './App.vue';
import 'lib-flexible/flexible.js';
import ElementUI from 'element-ui';
import axios from 'axios';
import router from './router/index';

Vue.prototype.$axios = axios;
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false;
import '@/assets/index.css';
import { Toast } from 'vant';

Vue.use(Toast);
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
