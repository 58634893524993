import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const constantRoutes = [
  {
    path: '/zer/:id',
    name: 'zer',
    props: true,
    component: () => import('@/views/zer/index'),
  },
  {
    path: '/shanghai',
    name: 'shanghai',
    props: true,
    component: () => import('@/views/shanghai/index'),
  },
];

const createRouter = () =>
  new Router({
    mode: 'hash', // require service support
    scrollBehavior: () => ({ y: 0 }),

    routes: [...constantRoutes],
  });
const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
